<template>
    <Dialog modal @show="getDetalhes" header="DETALHES ITEM AVARIADO" v-model:visible="visible" :style="{width: '60vw'}" closable @hide="$emit('fechar')">
        <span><strong>Descrição: {{ description }}</strong></span><hr />
        <DataTable showGridlines="true" :value="detalhes" responsiveLayout="stack">
            <Column header="Chuv/Ava Inicial">
                <template #body="{ data }">
                    {{ formatDecimal(data.percentualInicial) }}
                </template>
            </Column>

            <Column header="Chuv/Ava Final">
                <template #body="{ data }">
                    {{ formatDecimal(data.percentualFinal) }}
                </template>
            </Column>

            <Column header="Ph Inicial" v-if="visualizarColunasPh">
                <template #body="{ data }">
                    {{ formatDecimal(data.phInicial, 0, 0) }}
                </template>
            </Column>

            <Column header="Ph Final" v-if="visualizarColunasPh">
                <template #body="{ data }">
                    {{ formatDecimal(data.phFinal, 0, 0) }}
                </template>
            </Column>

            <Column header="FNT Inicial" v-if="visualizarColunasFnt">
                <template #body="{ data }">
                    {{ formatDecimal(data.fntInicial) }}
                </template>
            </Column>

            <Column header="FNT Final" v-if="visualizarColunasFnt">
                <template #body="{ data }">
                    {{ formatDecimal(data.fntFinal) }}
                </template>
            </Column>

            <Column field="produto" header="Produto">
                <template #body="{ data }">
                    <span>{{ data.produto.codigo }} - {{ data.produto.descricao }}</span>
                </template>
            </Column>
            <Column field="produtoReferencia.codRef" header="Referência"/>
        </DataTable>
    </Dialog>
</template>

<script>
    import ItensAvariadoService from '../../service/ItensAvariadoService';
import Formatacao from '../../utilities/Formatacao';
    export default {
        props: {
            id: {
                type: String,
                required: true
            },   
            
            visible: {
                type: Boolean,
                required: true
            },

            description: {
                type: String,
                required: false
            },

            visualizarColunasFnt: {
                type: Boolean,
                required: false,
                default: false
            },
            
            visualizarColunasPh: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        data() {
            return {
                detalhes: [],
            }
        },        

        methods: { 
            getDetalhes() {
                ItensAvariadoService.getItensAvariadoDetalhes(this.id)
                    .then(({ data }) => {
                        this.detalhes = data.detalhes;
                    });

            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits)
            }
        }
    }
</script>